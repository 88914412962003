<template>
  <div class="checkout__style">
    <v-row>
      <v-col cols="12" md="4">
        <h3>Abandoned checkouts email</h3>
        <p>Send an email to customers who left products in their cart but didn’t complete their order.</p>
      </v-col>
      <v-col cols="12" md="8" class="elevation-form">
        <v-checkbox v-model="form.abandonedCheckout.useAdvancedEmailFlow" label="Use Advanced Email Flow" />
        <v-checkbox
          hide-details
          v-model="form.abandonedCheckout.automaticSentAbandonedEmail"
          label="Automatically send abandoned checkout emails"
        />
        <v-divider class="my-5" v-if="!form.abandonedCheckout.useAdvancedEmailFlow" />
        <div :class="!form.abandonedCheckout.automaticSentAbandonedEmail ? 'overlay-setup' : ''">
          <div>
            <!-- <h3>Send to</h3>
          <v-radio-group v-model="$parent.form.abandonedCheckout.sentSmsToEveryone">
            <v-radio label="Anyone who abandons checkout" :value="true"></v-radio>
            <v-radio label="Email subscribers who abandons checkout" :value="false"></v-radio>
          </v-radio-group> -->
          </div>
          <div v-if="!form.abandonedCheckout.useAdvancedEmailFlow">
            <div>
              <!-- {{ $parent.form.abandonedCheckout.firstEmailScheduleTime.sentAutomatically }} -->
              <h3>Set up emails to send</h3>
              <v-checkbox
                hide-details
                label="First email"
                v-model="form.abandonedCheckout.firstEmailScheduleTime.sentAutomatically"
              ></v-checkbox>
              <div
                class="d-flex align-center my-3 justify-space-between"
                v-if="form.abandonedCheckout.firstEmailScheduleTime.sentAutomatically"
              >
                <div><strong>Send</strong></div>
                <div style="width: 200px">
                  <v-text-field
                    hide-details=""
                    type="number"
                    v-model="form.abandonedCheckout.firstEmailScheduleTime.sentAfter"
                    @input="
                      cacul(
                        totalFirstTime,
                        form.abandonedCheckout.firstEmailScheduleTime.sentAfter,
                        form.abandonedCheckout.firstEmailScheduleTime.sentAfterText,
                        'totalFirstTime',
                      )
                    "
                  ></v-text-field>
                </div>
                <div style="width: 200px">
                  <v-select
                    hide-details=""
                    :items="firstTime"
                    v-model="form.abandonedCheckout.firstEmailScheduleTime.sentAfterText"
                    @change="
                      cacul(
                        totalFirstTime,
                        form.abandonedCheckout.firstEmailScheduleTime.sentAfter,
                        form.abandonedCheckout.firstEmailScheduleTime.sentAfterText,
                        'totalFirstTime',
                      )
                    "
                  ></v-select>
                </div>
                <div class="ml-3">after cart abandonment</div>
              </div>
              <div v-if="errorMessages1 != ''" class="error--text">{{ errorMessages1 }}</div>
              <router-link
                class="mt-3 invent"
                :to="{ name: 'website.settings.notifications.order.abandoned.checkout.email.1' }"
                >Customize Abandoned checkout - First email
              </router-link>
            </div>
            <div>
              <v-checkbox
                hide-details
                label="Second email"
                v-model="form.abandonedCheckout.secondEmailScheduleTime.sentAutomatically"
              ></v-checkbox>
              <div
                class="d-flex align-center my-3 justify-space-between"
                v-if="form.abandonedCheckout.secondEmailScheduleTime.sentAutomatically"
              >
                <div><strong>Send</strong></div>
                <div style="width: 200px">
                  <v-text-field
                    hide-details=""
                    type="number"
                    v-model="form.abandonedCheckout.secondEmailScheduleTime.sentAfter"
                    @input="
                      cacul(
                        totalSecondTime,
                        form.abandonedCheckout.secondEmailScheduleTime.sentAfter,
                        form.abandonedCheckout.secondEmailScheduleTime.sentAfterText,
                        'totalSecondTime',
                      )
                    "
                  ></v-text-field>
                </div>
                <div style="width: 200px">
                  <v-select
                    hide-details=""
                    :items="secondTime"
                    v-model="form.abandonedCheckout.secondEmailScheduleTime.sentAfterText"
                    @change="
                      cacul(
                        totalSecondTime,
                        form.abandonedCheckout.secondEmailScheduleTime.sentAfter,
                        form.abandonedCheckout.secondEmailScheduleTime.sentAfterText,
                        'totalSecondTime',
                      )
                    "
                  ></v-select>
                </div>
                <div class="ml-3">after cart abandonment</div>
              </div>
              <div v-if="errorMessages2 != ''" class="error--text">{{ errorMessages2 }}</div>
              <router-link
                class="mt-3 invent"
                :to="{ name: 'website.settings.notifications.order.abandoned.checkout.email.2' }"
                >Customize Abandoned checkout - Second email
              </router-link>
            </div>
            <div>
              <v-checkbox
                hide-details
                label="Third email"
                v-model="form.abandonedCheckout.thirdEmailScheduleTime.sentAutomatically"
              ></v-checkbox>
              <div
                class="d-flex align-center my-3 justify-space-between"
                v-if="form.abandonedCheckout.thirdEmailScheduleTime.sentAutomatically"
              >
                <div><strong>Send</strong></div>
                <div style="width: 200px">
                  <v-text-field
                    hide-details=""
                    type="number"
                    v-model="form.abandonedCheckout.thirdEmailScheduleTime.sentAfter"
                    @input="
                      cacul(
                        totalThirdTime,
                        form.abandonedCheckout.thirdEmailScheduleTime.sentAfter,
                        form.abandonedCheckout.thirdEmailScheduleTime.sentAfterText,
                        'totalThirdTime',
                      )
                    "
                  ></v-text-field>
                </div>
                <div style="width: 200px">
                  <v-select
                    hide-details=""
                    :items="thirdTime"
                    v-model="form.abandonedCheckout.thirdEmailScheduleTime.sentAfterText"
                    @change="
                      cacul(
                        totalThirdTime,
                        form.abandonedCheckout.thirdEmailScheduleTime.sentAfter,
                        form.abandonedCheckout.thirdEmailScheduleTime.sentAfterText,
                        'totalThirdTime',
                      )
                    "
                  ></v-select>
                </div>
                <div class="ml-3">after cart abandonment</div>
              </div>
              <div v-if="errorMessages3 != ''" class="error--text">{{ errorMessages3 }}</div>
              <router-link
                class="mt-3 invent"
                :to="{ name: 'website.settings.notifications.order.abandoned.checkout.email.3' }"
                >Customize Abandoned checkout - Third email
              </router-link>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ['form'],
  data() {
    let time = [
      {
        text: 'minute(s)',
        value: 'm',
      },
      {
        text: 'hour(s)',
        value: 'h',
      },
      {
        text: 'day(s)',
        value: 'd',
      },
    ];
    return {
      total: '',
      firstTime: time.map(x => x),
      secondTime: time.map(x => x),
      thirdTime: time.map(x => x),
      totalFirstTime: '',
      totalSecondTime: '',
      totalThirdTime: '',
      errorMessages1: '',
      errorMessages2: '',
      errorMessages3: '',
    };
  },
  mounted() {
    this.cacul(
      this.totalFirstTime,
      this.form.abandonedCheckout.firstEmailScheduleTime.sentAfter,
      this.form.abandonedCheckout.firstEmailScheduleTime.sentAfterText,
      'totalFirstTime',
    );
    this.cacul(
      this.totalSecondTime,
      this.form.abandonedCheckout.secondEmailScheduleTime.sentAfter,
      this.form.abandonedCheckout.secondEmailScheduleTime.sentAfterText,
      'totalSecondTime',
    );
    this.cacul(
      this.totalThirdTime,
      this.form.abandonedCheckout.thirdEmailScheduleTime.sentAfter,
      this.form.abandonedCheckout.thirdEmailScheduleTime.sentAfterText,
      'totalThirdTime',
    );
  },
  methods: {
    cacul(total, sentAfter, sentAfterText, mailType) {
      if (sentAfterText == 'm') {
        total = sentAfter * 60;
      } else if (sentAfterText == 'h') {
        total = sentAfter * 60 * 60;
      } else if (sentAfterText == 'd') {
        total = sentAfter * 60 * 60 * 24;
      }
      if (mailType == 'totalFirstTime') {
        this.totalFirstTime = total;
      }
      if (mailType == 'totalSecondTime') {
        this.totalSecondTime = total;
      }
      if (mailType == 'totalThirdTime') {
        this.totalThirdTime = total;
      }
    },
    // cacul2(total, sentAfter, sentAfterText) {
    //   if (sentAfterText == 'm') {
    //     total = sentAfter * 60;
    //   } else if (sentAfterText == 'h') {
    //     total = sentAfter * 60 * 60;
    //   } else if (sentAfterText == 'd') {
    //     total = sentAfter * 60 * 60 * 24;
    //   }
    //   this.totalSecondTime = total;
    //   console.log(total);
    // },
    // cacul3(total, sentAfter, sentAfterText) {
    //   if (sentAfterText == 'm') {
    //     total = sentAfter * 60;
    //   } else if (sentAfterText == 'h') {
    //     total = sentAfter * 60 * 60;
    //   } else if (sentAfterText == 'd') {
    //     total = sentAfter * 60 * 60 * 24;
    //   }
    //   this.totalThirdTime = total;
    //   console.log(total);
    // },
  },
  watch: {
    totalFirstTime() {
      if (this.$parent.form.abandonedCheckout.automaticSentAbandonedEmail) {
        if (this.totalFirstTime <= 0) {
          this.errorMessages1 = 'The value must greater than 0.';
        }
        if (this.totalFirstTime > 0) {
          this.errorMessages1 = '';
          if (
            this.totalFirstTime > this.totalSecondTime &&
            this.$parent.form.abandonedCheckout.firstEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.firstEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondEmailScheduleTime.sentAfterText != ''
          ) {
            this.errorMessages2 = 'Sending time of subsequent texts must be longer than sending time of previous ones.';
          } else {
            this.errorMessages2 = '';
          }
        }
      }
    },
    totalSecondTime() {
      if (this.$parent.form.abandonedCheckout.automaticSentAbandonedEmail) {
        if (this.totalSecondTime <= 0) {
          this.errorMessages2 = 'The value must greater than 0.';
        }
        if (this.totalSecondTime > 0) {
          this.errorMessages2 = '';
          if (
            this.totalSecondTime > this.totalThirdTime &&
            this.$parent.form.abandonedCheckout.secondEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondEmailScheduleTime.sentAfterText != '' &&
            this.$parent.form.abandonedCheckout.thirdEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.thirdEmailScheduleTime.sentAfterText != ''
          ) {
            this.errorMessages3 = 'Sending time of subsequent texts must be longer than sending time of previous ones.';
          }
          if (this.totalSecondTime < this.totalThirdTime) {
            this.errorMessages3 = '';
          }
          if (
            this.totalSecondTime < this.totalFirstTime &&
            this.$parent.form.abandonedCheckout.firstEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.firstEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondEmailScheduleTime.sentAfterText != ''
          ) {
            this.errorMessages2 = 'Sending time of subsequent texts must be longer than sending time of previous ones.';
          }
          if (this.totalSecondTime > this.totalFirstTime) {
            this.errorMessages2 = '';
          }
        }
      }
    },
    totalThirdTime() {
      if (this.$parent.form.abandonedCheckout.automaticSentAbandonedEmail) {
        if (this.totalThirdTime <= 0) {
          this.errorMessages3 = 'The value must greater than 0.';
        }
        if (this.totalThirdTime > 0) {
          this.errorMessages3 = '';
          if (
            this.totalSecondTime > this.totalThirdTime &&
            this.$parent.form.abandonedCheckout.firstEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.firstEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondEmailScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondEmailScheduleTime.sentAfterText != ''
          ) {
            this.errorMessages3 = 'Sending time of subsequent texts must be longer than sending time of previous ones.';
          } else {
            this.errorMessages3 = '';
          }
        }
      }
    },
    errorMessages1() {
      if (this.errorMessages1 != '') {
        this.$parent.isDisable1 = true;
      } else {
        this.$parent.isDisable1 = false;
      }
    },
    errorMessages2() {
      if (this.errorMessages2 != '') {
        this.$parent.isDisable2 = true;
      } else {
        this.$parent.isDisable2 = false;
      }
    },
    errorMessages3() {
      if (this.errorMessages3 != '') {
        this.$parent.isDisable3 = true;
      } else {
        this.$parent.isDisable3 = false;
      }
    },
  },
};
</script>
<style lang="scss">
.overlay-setup {
  opacity: 0.5;
}
</style>
