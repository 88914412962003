var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checkout__style"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h3',[_vm._v("Abandoned checkouts text message")]),_c('small',[_vm._v("(highly recommend)")]),_c('p',[_vm._v(" Send an text message to customers who left products in their cart but didn’t complete their order. Research shows that SMS open rates are as high as 98%, compared to just 20% of all emails. ")])]),_c('v-col',{staticClass:"elevation-form",attrs:{"cols":"12","md":"8"}},[_c('v-checkbox',{attrs:{"hide-details":"","label":"Automatically send abandoned checkout sms"},model:{value:(_vm.form.abandonedCheckout.automaticSentAbandonedSms),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout, "automaticSentAbandonedSms", $$v)},expression:"form.abandonedCheckout.automaticSentAbandonedSms"}}),_c('v-divider',{staticClass:"my-5"}),_c('div',{class:!_vm.form.abandonedCheckout.automaticSentAbandonedSms ? 'overlay-setup' : ''},[_c('div',[_c('h3',[_vm._v("Set up text messages to send")]),_c('v-checkbox',{attrs:{"hide-details":"","label":"First text message"},model:{value:(_vm.form.abandonedCheckout.firstSmsScheduleTime.sentAutomatically),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout.firstSmsScheduleTime, "sentAutomatically", $$v)},expression:"form.abandonedCheckout.firstSmsScheduleTime.sentAutomatically"}}),(_vm.form.abandonedCheckout.firstSmsScheduleTime.sentAutomatically)?_c('div',{staticClass:"d-flex align-center my-3 justify-space-between"},[_c('div',[_c('strong',[_vm._v("Send")])]),_c('div',{staticStyle:{"width":"200px"}},[_c('v-text-field',{attrs:{"hide-details":"","type":"number"},on:{"input":function($event){return _vm.cacul(
                    _vm.totalFirstTime,
                    _vm.form.abandonedCheckout.firstSmsScheduleTime.sentAfter,
                    _vm.form.abandonedCheckout.firstSmsScheduleTime.sentAfterText,
                    'totalFirstTime'
                  )}},model:{value:(_vm.form.abandonedCheckout.firstSmsScheduleTime.sentAfter),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout.firstSmsScheduleTime, "sentAfter", $$v)},expression:"form.abandonedCheckout.firstSmsScheduleTime.sentAfter"}})],1),_c('div',{staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"hide-details":"","items":_vm.firstTime},on:{"change":function($event){return _vm.cacul(
                    _vm.totalFirstTime,
                    _vm.form.abandonedCheckout.firstSmsScheduleTime.sentAfter,
                    _vm.form.abandonedCheckout.firstSmsScheduleTime.sentAfterText,
                    'totalFirstTime'
                  )}},model:{value:(_vm.form.abandonedCheckout.firstSmsScheduleTime.sentAfterText),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout.firstSmsScheduleTime, "sentAfterText", $$v)},expression:"form.abandonedCheckout.firstSmsScheduleTime.sentAfterText"}})],1),_c('div',{staticClass:"ml-3"},[_vm._v("after cart abandonment")])]):_vm._e(),_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('strong',[_vm._v("Message")])])]),_c('InputDefault',{attrs:{"label":"","typeComponent":"area","model":['textMessage1']}}),_c('div',{staticClass:"d-flex mt-3"},[_c('div',[_vm._v(" Add variables: "),_vm._l((_vm.templateVariant),function(item,index){return _c('p',{key:index,staticClass:"mb-0 my-1 px-1 bg-template-variant"},[_c('v-btn',{staticClass:"bg-template-variant btn-link",attrs:{"small":""},on:{"click":function($event){return _vm.addText('message1', item.title)}}},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.title)+" : "+_vm._s(item.description))])])],1)})],2),_c('div',{staticClass:"ml-auto"},[_vm._v("Characters : "+_vm._s(_vm.message1Length)+"/153 | SMS counter: "+_vm._s(_vm.smsCounter1))])])],1),(_vm.errorMessages1)?_c('div',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.errorMessages1))]):_vm._e()],1),_c('div',[_c('v-checkbox',{attrs:{"hide-details":"","label":"Second text message"},model:{value:(_vm.form.abandonedCheckout.secondSmsScheduleTime.sentAutomatically),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout.secondSmsScheduleTime, "sentAutomatically", $$v)},expression:"form.abandonedCheckout.secondSmsScheduleTime.sentAutomatically"}}),(_vm.form.abandonedCheckout.secondSmsScheduleTime.sentAutomatically)?_c('div',{staticClass:"d-flex align-center my-3 justify-space-between"},[_c('div',[_c('strong',[_vm._v("Send")])]),_c('div',{staticStyle:{"width":"200px"}},[_c('v-text-field',{attrs:{"hide-details":"","type":"number"},on:{"input":function($event){return _vm.cacul(
                    _vm.totalSecondTime,
                    _vm.form.abandonedCheckout.secondSmsScheduleTime.sentAfter,
                    _vm.form.abandonedCheckout.secondSmsScheduleTime.sentAfterText,
                    'totalSecondTime'
                  )}},model:{value:(_vm.form.abandonedCheckout.secondSmsScheduleTime.sentAfter),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout.secondSmsScheduleTime, "sentAfter", $$v)},expression:"form.abandonedCheckout.secondSmsScheduleTime.sentAfter"}})],1),_c('div',{staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"hide-details":"","items":_vm.secondTime},on:{"change":function($event){return _vm.cacul(
                    _vm.totalSecondTime,
                    _vm.form.abandonedCheckout.secondSmsScheduleTime.sentAfter,
                    _vm.form.abandonedCheckout.secondSmsScheduleTime.sentAfterText,
                    'totalSecondTime'
                  )}},model:{value:(_vm.form.abandonedCheckout.secondSmsScheduleTime.sentAfterText),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout.secondSmsScheduleTime, "sentAfterText", $$v)},expression:"form.abandonedCheckout.secondSmsScheduleTime.sentAfterText"}})],1),_c('div',{staticClass:"ml-3"},[_vm._v("after cart abandonment")])]):_vm._e(),_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('strong',[_vm._v("Message")])])]),_c('InputDefault',{attrs:{"label":"","typeComponent":"area","model":['textMessage2']}}),_c('div',{staticClass:"d-flex mt-3"},[_c('div',[_vm._v(" Add variables: "),_vm._l((_vm.templateVariant),function(item,index){return _c('p',{key:index,staticClass:"mb-0 my-1 px-1 bg-template-variant"},[_c('v-btn',{staticClass:"bg-template-variant btn-link",attrs:{"small":""},on:{"click":function($event){return _vm.addText('message2', item.title)}}},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.title)+" : "+_vm._s(item.description))])])],1)})],2),_c('div',{staticClass:"ml-auto"},[_vm._v("Characters : "+_vm._s(_vm.message2Length)+"/153 | SMS counter: "+_vm._s(_vm.smsCounter2))])])],1),(_vm.errorMessages2)?_c('div',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.errorMessages2))]):_vm._e()],1),_c('div',[_c('v-checkbox',{attrs:{"hide-details":"","label":"Third text message"},model:{value:(_vm.form.abandonedCheckout.thirdSmsScheduleTime.sentAutomatically),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout.thirdSmsScheduleTime, "sentAutomatically", $$v)},expression:"form.abandonedCheckout.thirdSmsScheduleTime.sentAutomatically"}}),(_vm.form.abandonedCheckout.thirdSmsScheduleTime.sentAutomatically)?_c('div',{staticClass:"d-flex align-center my-3 justify-space-between"},[_c('div',[_c('strong',[_vm._v("Send")])]),_c('div',{staticStyle:{"width":"200px"}},[_c('v-text-field',{attrs:{"hide-details":"","type":"number"},on:{"input":function($event){return _vm.cacul(
                    _vm.totalThirdTime,
                    _vm.form.abandonedCheckout.thirdSmsScheduleTime.sentAfter,
                    _vm.form.abandonedCheckout.thirdSmsScheduleTime.sentAfterText,
                    'totalThirdTime'
                  )}},model:{value:(_vm.form.abandonedCheckout.thirdSmsScheduleTime.sentAfter),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout.thirdSmsScheduleTime, "sentAfter", $$v)},expression:"form.abandonedCheckout.thirdSmsScheduleTime.sentAfter"}})],1),_c('div',{staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"hide-details":"","items":_vm.thirdTime},on:{"change":function($event){return _vm.cacul(
                    _vm.totalThirdTime,
                    _vm.form.abandonedCheckout.thirdSmsScheduleTime.sentAfter,
                    _vm.form.abandonedCheckout.thirdSmsScheduleTime.sentAfterText,
                    'totalThirdTime'
                  )}},model:{value:(_vm.form.abandonedCheckout.thirdSmsScheduleTime.sentAfterText),callback:function ($$v) {_vm.$set(_vm.form.abandonedCheckout.thirdSmsScheduleTime, "sentAfterText", $$v)},expression:"form.abandonedCheckout.thirdSmsScheduleTime.sentAfterText"}})],1),_c('div',{staticClass:"ml-3"},[_vm._v("after cart abandonment")])]):_vm._e(),_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('strong',[_vm._v("Message")])])]),_c('InputDefault',{attrs:{"label":"","typeComponent":"area","model":['textMessage3']}}),_c('div',{staticClass:"d-flex mt-3"},[_c('div',[_vm._v(" Add variables: "),_vm._l((_vm.templateVariant),function(item,index){return _c('p',{key:index,staticClass:"mb-0 my-1 px-1 bg-template-variant"},[_c('v-btn',{staticClass:"bg-template-variant btn-link",attrs:{"small":""},on:{"click":function($event){return _vm.addText('message3', item.title)}}},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.title)+" : "+_vm._s(item.description))])])],1)})],2),_c('div',{staticClass:"ml-auto"},[_vm._v("Characters : "+_vm._s(_vm.message3Length)+"/153 | SMS counter: "+_vm._s(_vm.smsCounter3))])])],1),(_vm.errorMessages3 != '')?_c('div',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.errorMessages3))]):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }