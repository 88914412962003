<template>
  <div class="tip">
    <v-row>
      <v-col cols="12" md="4">
        <h3>Tipping</h3>
        <p>Include the option for customers to add a tip at checkout.</p>
      </v-col>
      <v-col cols="12" md="8" class="elevation-form">
        <v-checkbox
          v-model="$parent.form.tip.isShow"
          hide-details
          label="Show tipping options at checkout"
        ></v-checkbox>
        <p class="mt-2">Customers can add tip to their online purchase and show their support for your business.</p>
        <v-divider class="my-5"></v-divider>
        <div v-if="$parent.form.tip.isShow">
          <div cols="12" class="form-component">
            <label for="">Tip Name</label>
            <v-text-field v-model="$parent.form.tip.name"></v-text-field>
          </div>
          <div cols="12" class="form-component">
            <label for="">Tip Message</label>
            <v-text-field v-model="$parent.form.tip.message"></v-text-field>
          </div>
          <div cols="12" class="form-component">
            <label class="mb-2" for="">Tip Options</label>
            <div v-for="(option, index) in $parent.form.tip.options" :key="`${index}s`">
              <span>{{ `Option ` + option.id }}</span>
              <v-text-field type="number" v-model.number="option.value" suffix="%"></v-text-field>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
export default {
  data() {
    return {
      show: false,
      tip: {
        name: 'Show your support for the team at {{shop.name}}',
        message: 'Thank you, we appreciate it.',
        options: [
          {
            name: 'Option 1',
            value: '',
          },
          {
            name: 'Option 2',
            value: '',
          },
          {
            name: 'Option 3',
            value: '',
          },
        ],
      },
    };
  },
  validations: {
    tip: {
      name: {
        required,
      },
    },
  },
};
</script>
