<template>
  <div class="checkout__style">
    <v-row>
      <v-col cols="12" md="4">
        <h3>Abandoned checkouts text message</h3>
        <small>(highly recommend)</small>
        <p>
          Send an text message to customers who left products in their cart but didn’t complete their order. Research
          shows that SMS open rates are as high as 98%, compared to just 20% of all emails.
        </p>
      </v-col>
      <v-col cols="12" md="8" class="elevation-form">
        <v-checkbox
          hide-details
          v-model="form.abandonedCheckout.automaticSentAbandonedSms"
          label="Automatically send abandoned checkout sms"
        ></v-checkbox>
        <v-divider class="my-5"></v-divider>
        <div :class="!form.abandonedCheckout.automaticSentAbandonedSms ? 'overlay-setup' : ''">
          <div>
            <h3>Set up text messages to send</h3>

            <!-- Start First text message -->
            <v-checkbox
              hide-details
              label="First text message"
              v-model="form.abandonedCheckout.firstSmsScheduleTime.sentAutomatically"
            ></v-checkbox>
            <div
              class="d-flex align-center my-3 justify-space-between"
              v-if="form.abandonedCheckout.firstSmsScheduleTime.sentAutomatically"
            >
              <div><strong>Send</strong></div>
              <div style="width: 200px">
                <v-text-field
                  hide-details=""
                  type="number"
                  v-model="form.abandonedCheckout.firstSmsScheduleTime.sentAfter"
                  @input="
                    cacul(
                      totalFirstTime,
                      form.abandonedCheckout.firstSmsScheduleTime.sentAfter,
                      form.abandonedCheckout.firstSmsScheduleTime.sentAfterText,
                      'totalFirstTime',
                    )
                  "
                ></v-text-field>
              </div>
              <div style="width: 200px">
                <v-select
                  hide-details=""
                  :items="firstTime"
                  v-model="form.abandonedCheckout.firstSmsScheduleTime.sentAfterText"
                  @change="
                    cacul(
                      totalFirstTime,
                      form.abandonedCheckout.firstSmsScheduleTime.sentAfter,
                      form.abandonedCheckout.firstSmsScheduleTime.sentAfterText,
                      'totalFirstTime',
                    )
                  "
                ></v-select>
              </div>
              <div class="ml-3">after cart abandonment</div>
            </div>

            <div>
              <div class="d-flex align-center">
                <div><strong>Message</strong></div>
              </div>
              <InputDefault label="" typeComponent="area" :model="['textMessage1']" />

              <div class="d-flex mt-3">
                <div>
                  Add variables:
                  <p class="mb-0 my-1 px-1 bg-template-variant" v-for="(item, index) in templateVariant" :key="index">
                    <v-btn @click="addText('message1', item.title)" small class="bg-template-variant btn-link">
                      <span class="black--text">{{ item.title }} : {{ item.description }}</span>
                    </v-btn>
                  </p>
                </div>
                <div class="ml-auto">Characters : {{ message1Length }}/153 | SMS counter: {{ smsCounter1 }}</div>
              </div>
            </div>
            <div v-if="errorMessages1" class="error--text">{{ errorMessages1 }}</div>
          </div>
          <!-- End First text message -->
          <!-- Start second text message -->
          <div>
            <v-checkbox
              hide-details
              label="Second text message"
              v-model="form.abandonedCheckout.secondSmsScheduleTime.sentAutomatically"
            ></v-checkbox>
            <div
              class="d-flex align-center my-3 justify-space-between"
              v-if="form.abandonedCheckout.secondSmsScheduleTime.sentAutomatically"
            >
              <div><strong>Send</strong></div>
              <div style="width: 200px">
                <v-text-field
                  hide-details=""
                  type="number"
                  v-model="form.abandonedCheckout.secondSmsScheduleTime.sentAfter"
                  @input="
                    cacul(
                      totalSecondTime,
                      form.abandonedCheckout.secondSmsScheduleTime.sentAfter,
                      form.abandonedCheckout.secondSmsScheduleTime.sentAfterText,
                      'totalSecondTime',
                    )
                  "
                ></v-text-field>
              </div>
              <div style="width: 200px">
                <v-select
                  hide-details=""
                  :items="secondTime"
                  v-model="form.abandonedCheckout.secondSmsScheduleTime.sentAfterText"
                  @change="
                    cacul(
                      totalSecondTime,
                      form.abandonedCheckout.secondSmsScheduleTime.sentAfter,
                      form.abandonedCheckout.secondSmsScheduleTime.sentAfterText,
                      'totalSecondTime',
                    )
                  "
                ></v-select>
              </div>
              <div class="ml-3">after cart abandonment</div>
            </div>
            <div>
              <div class="d-flex align-center">
                <div><strong>Message</strong></div>
              </div>
              <InputDefault label="" typeComponent="area" :model="['textMessage2']" />
              <div class="d-flex mt-3">
                <div>
                  Add variables:
                  <p class="mb-0 my-1 px-1 bg-template-variant" v-for="(item, index) in templateVariant" :key="index">
                    <v-btn @click="addText('message2', item.title)" small class="bg-template-variant btn-link">
                      <span class="black--text">{{ item.title }} : {{ item.description }}</span>
                    </v-btn>
                  </p>
                </div>
                <div class="ml-auto">Characters : {{ message2Length }}/153 | SMS counter: {{ smsCounter2 }}</div>
              </div>
            </div>
            <div v-if="errorMessages2" class="error--text">{{ errorMessages2 }}</div>
          </div>
          <!-- End second text message -->
          <!-- Start third text message -->
          <div>
            <v-checkbox
              hide-details
              label="Third text message"
              v-model="form.abandonedCheckout.thirdSmsScheduleTime.sentAutomatically"
            ></v-checkbox>
            <div
              class="d-flex align-center my-3 justify-space-between"
              v-if="form.abandonedCheckout.thirdSmsScheduleTime.sentAutomatically"
            >
              <div><strong>Send</strong></div>
              <div style="width: 200px">
                <v-text-field
                  hide-details=""
                  type="number"
                  v-model="form.abandonedCheckout.thirdSmsScheduleTime.sentAfter"
                  @input="
                    cacul(
                      totalThirdTime,
                      form.abandonedCheckout.thirdSmsScheduleTime.sentAfter,
                      form.abandonedCheckout.thirdSmsScheduleTime.sentAfterText,
                      'totalThirdTime',
                    )
                  "
                ></v-text-field>
              </div>
              <div style="width: 200px">
                <v-select
                  hide-details=""
                  :items="thirdTime"
                  v-model="form.abandonedCheckout.thirdSmsScheduleTime.sentAfterText"
                  @change="
                    cacul(
                      totalThirdTime,
                      form.abandonedCheckout.thirdSmsScheduleTime.sentAfter,
                      form.abandonedCheckout.thirdSmsScheduleTime.sentAfterText,
                      'totalThirdTime',
                    )
                  "
                ></v-select>
              </div>
              <div class="ml-3">after cart abandonment</div>
            </div>
            <div>
              <div class="d-flex align-center">
                <div><strong>Message</strong></div>
                <!-- <v-checkbox class="ml-auto" label="Add opt out text"></v-checkbox> -->
              </div>
              <InputDefault label="" typeComponent="area" :model="['textMessage3']" />
              <div class="d-flex mt-3">
                <div>
                  Add variables:
                  <p class="mb-0 my-1 px-1 bg-template-variant" v-for="(item, index) in templateVariant" :key="index">
                    <v-btn @click="addText('message3', item.title)" small class="bg-template-variant btn-link">
                      <span class="black--text">{{ item.title }} : {{ item.description }}</span>
                    </v-btn>
                  </p>
                </div>
                <div class="ml-auto">Characters : {{ message3Length }}/153 | SMS counter: {{ smsCounter3 }}</div>
              </div>
            </div>
            <div v-if="errorMessages3 != ''" class="error--text">{{ errorMessages3 }}</div>
          </div>
          <!-- End third text message -->
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { settingsApi } from '@/apis/settings';
export default {
  props: ['form'],
  data() {
    let time = [
      {
        text: 'minute(s)',
        value: 'm',
      },
      {
        text: 'hour(s)',
        value: 'h',
      },
      {
        text: 'day(s)',
        value: 'd',
      },
    ];
    return {
      total: '',
      formData: '',
      firstTime: time.map(x => x),
      secondTime: time.map(x => x),
      thirdTime: time.map(x => x),
      totalFirstTime: '',
      totalSecondTime: '',
      totalThirdTime: '',
      textMessage1: '',
      textMessage2: '',
      textMessage3: '',
      errorMessages1: '',
      errorMessages2: '',
      errorMessages3: '',
      templateVariant: [
        {
          title: '{{ shop.name }}',
          description: 'Your shop name.',
          href: '',
        },
        {
          title: '{{ .customer.first_name }}',
          description: `Customer's first name`,
          href: '',
        },
        {
          title: '{{ .abandoned_checkout.url }}',
          description: 'Abandoned checkout URL',
          href: '',
        },
      ],
      notifications: {},
    };
  },
  computed: {
    message1Length() {
      return this.textMessage1.length;
    },
    smsCounter1() {
      return Math.ceil(this.message1Length / 153);
    },
    message2Length() {
      return this.textMessage2.length;
    },
    smsCounter2() {
      return Math.ceil(this.message2Length / 153);
    },
    message3Length() {
      return this.textMessage3.length;
    },
    smsCounter3() {
      return Math.ceil(this.message3Length / 153);
    },
    msg1SMS() {
      return this.notifications.find(x => x.type === 'abandoned_checkout_sms_1');
    },
    msg2SMS() {
      return this.notifications.find(x => x.type === 'abandoned_checkout_sms_2');
    },
    msg3SMS() {
      return this.notifications.find(x => x.type === 'abandoned_checkout_sms_3');
    },
  },
  async mounted() {
    this.cacul(
      this.totalFirstTime,
      this.form.abandonedCheckout.secondSmsScheduleTime.sentAfter,
      this.form.abandonedCheckout.secondSmsScheduleTime.sentAfterText,
      'totalFirstTime',
    );
    this.cacul(
      this.totalSecondTime,
      this.form.abandonedCheckout.secondSmsScheduleTime.sentAfter,
      this.form.abandonedCheckout.secondSmsScheduleTime.sentAfterText,
      'totalSecondTime',
    );
    this.cacul(
      this.totalThirdTime,
      this.form.abandonedCheckout.thirdSmsScheduleTime.sentAfter,
      this.form.abandonedCheckout.thirdSmsScheduleTime.sentAfterText,
      'totalThirdTime',
    );

    let templates = await settingsApi.getNotifications();
    this.notifications = templates?.data || [];
    this.textMessage1 = this.msg1SMS ? this.msg1SMS.description : '';
    this.textMessage2 = this.msg2SMS ? this.msg2SMS.description : '';
    this.textMessage3 = this.msg3SMS ? this.msg3SMS.description : '';
  },
  methods: {
    cacul(total, sentAfter, sentAfterText, mailType) {
      if (sentAfterText == 'm') {
        total = sentAfter * 60;
      } else if (sentAfterText == 'h') {
        total = sentAfter * 60 * 60;
      } else if (sentAfterText == 'd') {
        total = sentAfter * 60 * 60 * 24;
      }
      if (mailType == 'totalFirstTime') {
        this.totalFirstTime = total;
      }
      if (mailType == 'totalSecondTime') {
        this.totalSecondTime = total;
      }
      if (mailType == 'totalThirdTime') {
        this.totalThirdTime = total;
      }
    },
    addText(message, content) {
      switch (message) {
        case 'message1':
          this.textMessage1 += ` ${content} `;
          break;
        case 'message2':
          this.textMessage2 += ` ${content} `;
          break;
        case 'message3':
          this.textMessage3 += ` ${content} `;
          break;
      }
    },
  },
  watch: {
    textMessage1() {
      this.$parent.form.textMessage1 = this.textMessage1;
    },
    textMessage2() {
      this.$parent.form.textMessage2 = this.textMessage2;
    },
    textMessage3() {
      this.$parent.form.textMessage3 = this.textMessage3;
    },
    totalFirstTime() {
      if (this.$parent.form.abandonedCheckout.automaticSentAbandonedEmail) {
        if (this.totalFirstTime <= 0) {
          this.errorMessages1 = 'The value must greater than 0.';
        }
        if (this.totalFirstTime > 0) {
          this.errorMessages1 = '';
          if (
            this.totalFirstTime > this.totalSecondTime &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfterText != ''
          ) {
            this.errorMessages2 = 'Sending time of subsequent texts must be longer than sending time of previous ones.';
          } else {
            this.errorMessages2 = '';
          }
        }
      }
    },
    totalSecondTime() {
      if (this.$parent.form.abandonedCheckout.automaticSentAbandonedEmail) {
        if (this.totalSecondTime <= 0) {
          this.errorMessages2 = 'The value must greater than 0.';
        }
        if (this.totalSecondTime > 0) {
          this.errorMessages2 = '';
          if (
            this.totalSecondTime > this.totalThirdTime &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfterText != '' &&
            this.$parent.form.abandonedCheckout.thirdSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.thirdSmsScheduleTime.sentAfterText != ''
          ) {
            this.errorMessages3 = 'Sending time of subsequent texts must be longer than sending time of previous ones.';
          }
          if (this.totalSecondTime < this.totalThirdTime) {
            this.errorMessages3 = '';
          }
          if (
            this.totalSecondTime < this.totalFirstTime &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfterText != ''
          ) {
            this.errorMessages2 = 'Sending time of subsequent texts must be longer than sending time of previous ones.';
          }
          if (this.totalSecondTime > this.totalFirstTime) {
            this.errorMessages2 = '';
          }
        }
      }
    },
    totalThirdTime() {
      if (this.$parent.form.abandonedCheckout.automaticSentAbandonedEmail) {
        if (this.totalThirdTime <= 0) {
          this.errorMessages3 = 'The value must greater than 0.';
        }
        if (this.totalThirdTime > 0) {
          this.errorMessages3 = '';
          if (
            this.totalSecondTime > this.totalThirdTime &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfter != '' &&
            this.$parent.form.abandonedCheckout.secondSmsScheduleTime.sentAfterText != ''
          ) {
            this.errorMessages3 = 'Sending time of subsequent texts must be longer than sending time of previous ones.';
          } else {
            this.errorMessages3 = '';
          }
        }
      }
    },
    errorMessages1() {
      if (this.errorMessages1 != '') {
        this.$parent.isDisable1 = true;
      } else {
        this.$parent.isDisable1 = false;
      }
    },
    errorMessages2() {
      if (this.errorMessages2 != '') {
        this.$parent.isDisable2 = true;
      } else {
        this.$parent.isDisable2 = false;
      }
    },
    errorMessages3() {
      if (this.errorMessages3 != '') {
        this.$parent.isDisable3 = true;
      } else {
        this.$parent.isDisable3 = false;
      }
    },
  },
};
</script>
<style lang="scss">
.overlay-setup {
  opacity: 0.5;
}
</style>
